import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { useTranslation } from 'react-i18next'

import { withTranslationHOC } from '../i18n/with-translation-hoc'
import { H1, Image, Layout, ImageWrapper, Wrapper } from '../components'

interface INotFoundPageProps {
  location: {
    pathname: string
  }
  data: {
    image: {
      childImageSharp: GatsbyImageFluidProps
    }
  }
}

const NotFound = memo(({ data, location }: INotFoundPageProps) => {
  const { t } = useTranslation()
  const { image } = data

  return (
    <Layout location={location}>
      <Wrapper>
        <H1>{t('error.pageNotFound')}</H1>
        <ImageWrapper>
          <Image img={image.childImageSharp} />
        </ImageWrapper>
      </Wrapper>
    </Layout>
  )
})

NotFound.displayName = 'NotFound'

export default withTranslationHOC(NotFound)

export const data = graphql`
  query NotFoundPageQuery {
    image: file(relativePath: { eq: "nothing-found.png" }) {
      ...fluidImage
    }
  }
`
